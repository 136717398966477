import React from "react";
import { Link } from "react-router-dom";
import SEO from "../common/SEO";
import Typed from "react-typed";
import HeaderOne from "../common/header/HeaderOne";
import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import FooterThree from "../common/footer/FooterThree";
import { FiArrowRight } from "react-icons/fi";

const Splash = () => {
  return (
    <>
      <SEO title="CHI NDI IGBO " />
      <main className="page-wrapper">
        <HeaderOne
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-not-transparent"
        />

        {/* Start Slider Area  */}
        <div className="slider-area slider-style-1 height-750">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner text-center">
                  <span className="subtitle">CHI NDI IGBO</span>
                  <br />
                  <h1 className="title theme-gradient display-two">
                    CHI NDI IGBO <br /> <hr />
                    <Typed
                      strings={["Broadcasting.", "Multi Media.", "Production.","Editing.","Product Marketing."]}
                      typeSpeed={80}
                      backSpeed={5}
                      backDelay={1000}
                      loop
                    />
                  </h1>
                  <br />
                  <br />
                  <p className="description">
                    A media house commited to serving and delivering top notch
                    results, in time and in place with our team of highly
                    trained professionals.
                    <br />
                    <br />
                    We explore culture, tradition, lifestyle, arts and
                    entertainment to bring you uniquely exciting and educative
                    content, about the Ibos and presented in Igbo Language.
                  </p>
                  <div className="button-group">
                    <Link className="btn-default btn-medium round btn-icon" to="/contact">Contact Us <i className="icon"><FiArrowRight /></i></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

        <Separator />

        {/* Start Splash  */}
        <div className="rn-demo-area rn-section-gap">
          <div className="wrapper plr--150 plr_lg--30 plr_md--30 plr_sm--30">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Production"
                  title="Catalog"
                //   description="<br /> History"
                />
              </div>
            </div>
            <div className="row row--30">
              {Videos.slice(0).reverse().map((video, index) => (
                <div className="col-lg-4 col-md-6 col-12 mt--40" key={index}>
                  <div className="single-demo">
                    <iframe
                      width="100%"
                      height="315px"
                      src={video.embedSrc}
                      title={video.title}
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                    <h4 className="title">
                      <a target="_blank" href={`${video.src}`} rel="noreferrer">
                        {video.title.toUpperCase()}
                      </a>
                    </h4>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* End Splash  */}

        <FooterThree />
      </main>
    </>
  );
};
const Videos = [
  {
    title: "NKALITE AHIA 'ENE GLOBAL FURNITURE",
    src: "https://www.youtube.com/watch?v=qep10jx5wKk&ab_channel=ChindigboMedia",
    embedSrc: "https://www.youtube.com/embed/qep10jx5wKk",
  },

  {
    title: "ONYE KWUO UCHE YA",
    embedSrc: "https://www.youtube.com/embed/9VW1DoNoSEw",
    src: "https://www.youtube.com/watch?v=9VW1DoNoSEw&ab_channel=ChindigboMedia",
  },
  {
    title: "Nke a bụ nke anyị. Nọgide na-ele Chindigbo",
    embedSrc: "https://www.youtube.com/embed/1jDlV1myFTk",
    src: "https://youtu.be/1jDlV1myFTk",
  },
  {
    title: "EMUME MRS. NIGERIA 2021",
    embedSrc: "https://www.youtube.com/embed/2eYIPspI6ok",
    src: "https://youtu.be/2eYIPspI6ok",
  },
  {
    title: "NDỊ MMADỤ NA ECHICHE: Ọrụ Na Nchekwa Ọrụ",
    embedSrc: "https://www.youtube.com/embed/91fzoce8PPI",
    src: "https://youtu.be/91fzoce8PPI",
  },
  {
    title: "Ndụmọdụ maka ndị chọrọ ịlụ di na nwunye",
    embedSrc: "https://www.youtube.com/embed/pxhpNwFA6wc",
    src: "https://youtu.be/pxhpNwFA6wc",
  },
  {
    title: "Ezinne: Njirimara Ezigbo Nwanyi",
    embedSrc: "https://www.youtube.com/embed/9MZ-HJFRvtk",
    src: "https://youtu.be/9MZ-HJFRvtk",
  },
  {
    title: "Kedụ Ihe Eji Mara Ndị Bé Unu?",
    embedSrc: "https://www.youtube.com/embed/HFUVCafQzHc",
    src: "https://youtu.be/HFUVCafQzHc",
  },
  {
    title: "EZINNE: IHE EJIRI MARA EZIGBO NWANYI",
    embedSrc: "https://www.youtube.com/embed/fLi4wspnrdY",
    src: "https://youtu.be/fLi4wspnrdY",
  },
  {
    title:  "MKPA TEKNỤZỤ BARA NA NDỤ ANYỊ",
    src: "https://youtu.be/5vvfM_vU6i0",
    embedSrc: "https://www.youtube.com/embed/5vvfM_vU6i0",
  },
  {
    title: "Mkpagbu Nke Ime Obi | Depression",
    src: "https://youtu.be/xH_lL0cMotM",
    embedSrc: "https://www.youtube.com/embed/xH_lL0cMotM",
  },
  {
    title: "Sorom Chia: Ndu Aguru | Comedy Skit",
    src: "https://youtu.be/f4h0cCoYCmM",
    embedSrc: "https://www.youtube.com/embed/f4h0cCoYCmM",
  },
  
  {
    title: "KA ỤMỤNWANYỊ GA-ESI CHỌTA DI ỌMA",
    embedSrc: "https://www.youtube.com/embed/7GHew9NqVww",
    src: "https://youtu.be/7GHew9NqVww",
  },
  {
    title: "Ajụjụ Ọnụ Nke Mazi 'Lyrical Ogele' | (Interview with Lyrical Ogele)",
    embedSrc: "https://www.youtube.com/embed/WTHC6GTvBm8",
    src: "https://youtu.be/WTHC6GTvBm8",
  },
  {
    title: "NDI NTORỌBIA NA ỌNWA ỊHỤNANYA",
    embedSrc: "https://www.youtube.com/embed/Kz8Zp6l7SF4",
    src: "https://youtu.be/Kz8Zp6l7SF4",
  },
  
];
export default Splash;
