import React from "react";
import {
  FiFacebook,
  FiHeadphones,
  FiInstagram,
  FiMail,
  FiMapPin,
  FiYoutube,
} from "react-icons/fi";

const ContactOne = () => {
  return (
    <>
      <div className="row row--15">
        <div className="col-lg-12">
          <div className="rn-contact-address mt_dec--30">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12">
                <a
                  href="https://www.youtube.com/channel/UCzy9RH3jLhG4dE67yASWZWA"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="rn-address">
                    <div className="icon">
                      <FiYoutube />
                    </div>
                    <div className="inner">
                      {" "}
                      <h4 className="title">Check us out on Youtube</h4>
                    </div>
                  </div>
                </a>
              </div>{" "}
              <div className="col-lg-4 col-md-6 col-12">
                <a
                  href="https://www.instagram.com/chindigbomedia/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="rn-address">
                    <div className="icon">
                      <FiInstagram />
                    </div>
                    <div className="inner">
                      {" "}
                      <h4 className="title">
                        Follow us on instagram &#128540;
                      </h4>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <a
                  href="https://www.facebook.com/chindigbomedia/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="rn-address">
                    <div className="icon">
                      <FiFacebook />
                    </div>
                    <div className="inner">
                      {" "}
                      <h4 className="title">Say hello on Facebook!</h4>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-address">
                  <div className="icon">
                    <FiMail />
                  </div>
                  <div className="inner">
                    <h4 className="title">Contact via Email</h4>
                    <p>
                      <a href="mailto:Chindigbotv@gmail.com">Chindigbotv@gmail.com</a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <a href="https://goo.gl/maps/uvpJAtq76qqxciVaA" target="_blank" rel="noreferrer">
                  <div className="rn-address">
                    <div className="icon">
                      <FiMapPin />
                    </div>
                    <div className="inner">
                      <h4 className="title">Visit us</h4>

                      <p>
                        18 Ramat Cres, Ogudu 105102, <br />
                        Lagos, Nigeria
                      </p>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-address">
                  <div className="icon">
                    <FiHeadphones />
                  </div>
                  <div className="inner">
                    <h4 className="title">
                      Contact Phone Number <br />
                      (SMS/WhatsApp)
                    </h4>
                    <p>
                      <a href="tel:+2347089097003">+234 7089097003</a>
                    </p>
                    <p>
                      <a href="tel:+2347066466105">
                        +234 7066466105
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt--40 row--15">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3963.5931293929143!2d3.388451050914625!3d6.57292342438564!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b929b6786ec9d%3A0x9196dc49e4b86b60!2s18%20Ramat%20Cres%2C%20Ogudu%20105102%2C%20Lagos%2C%20Nigeria!5e0!3m2!1sen!2sca!4v1630356354473!5m2!1sen!2sca"
          width="100%"
          height="450"
          allowfullscreen=""
          loading="lazy"
          title="Map Location"
        ></iframe>
        {/* <div className="col-lg-7">
          <ContactForm formStyle="contact-form-1" />
        </div>
        <div className="col-lg-5 mt_md--30 mt_sm--30">
          <GoogleMapStyle />
        </div> */}
      </div>
    </>
  );
};
export default ContactOne;
